import React from "react"
import styles from "./Navbar.module.sass"

const Navbar = () => {
	const atSignLogo = (
		<svg x="0" y="0" width="24" height="24" viewBox="0 0 24 24">
			<path
				fill="currentColor"
				d="M12 2C6.486 2 2 6.486 2 12C2 17.515 6.486 22 12 22C14.039 22 15.993 21.398 17.652 20.259L16.521 18.611C15.195 19.519 13.633 20 12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C16.411 4 20 7.589 20 12V12.782C20 14.17 19.402 15 18.4 15L18.398 15.018C18.338 15.005 18.273 15 18.209 15H18C17.437 15 16.6 14.182 16.6 13.631V12C16.6 9.464 14.537 7.4 12 7.4C9.463 7.4 7.4 9.463 7.4 12C7.4 14.537 9.463 16.6 12 16.6C13.234 16.6 14.35 16.106 15.177 15.313C15.826 16.269 16.93 17 18 17L18.002 16.981C18.064 16.994 18.129 17 18.195 17H18.4C20.552 17 22 15.306 22 12.782V12C22 6.486 17.514 2 12 2ZM12 14.599C10.566 14.599 9.4 13.433 9.4 11.999C9.4 10.565 10.566 9.399 12 9.399C13.434 9.399 14.6 10.565 14.6 11.999C14.6 13.433 13.434 14.599 12 14.599Z"
			></path>
		</svg>
	)

	const voiceCallLogo = (
		<svg x="0" y="0" width="24" height="24" viewBox="0 0 24 24">
			<path
				fill="currentColor"
				fillRule="evenodd"
				clipRule="evenodd"
				d="M11 5V3C16.515 3 21 7.486 21 13H19C19 8.589 15.411 5 11 5ZM17 13H15C15 10.795 13.206 9 11 9V7C14.309 7 17 9.691 17 13ZM11 11V13H13C13 11.896 12.105 11 11 11ZM14 16H18C18.553 16 19 16.447 19 17V21C19 21.553 18.553 22 18 22H13C6.925 22 2 17.075 2 11V6C2 5.447 2.448 5 3 5H7C7.553 5 8 5.447 8 6V10C8 10.553 7.553 11 7 11H6C6.063 14.938 9 18 13 18V17C13 16.447 13.447 16 14 16Z"
			></path>
		</svg>
	)

	const videoCallLogo = (
		<svg x="0" y="0" width="24" height="24" viewBox="0 0 24 24">
			<path
				fill="currentColor"
				d="M21.526 8.149C21.231 7.966 20.862 7.951 20.553 8.105L18 9.382V7C18 5.897 17.103 5 16 5H4C2.897 5 2 5.897 2 7V17C2 18.104 2.897 19 4 19H16C17.103 19 18 18.104 18 17V14.618L20.553 15.894C20.694 15.965 20.847 16 21 16C21.183 16 21.365 15.949 21.526 15.851C21.82 15.668 22 15.347 22 15V9C22 8.653 21.82 8.332 21.526 8.149Z"
			></path>
		</svg>
	)

	return (
		<div className={styles.container}>
			<div className={styles.atSignContainer}>
				<div className="svg">{atSignLogo}</div>
				Dummy Name
			</div>
			<div className={styles.buttons}>
				<a className={styles.button} href="#">
					{voiceCallLogo}
				</a>
				<a className={styles.button} href="#">
					{videoCallLogo}
				</a>
			</div>
		</div>
	)
}

export default Navbar
